<template>
  <section>
      <div class="pcoded-wrapper">
        <div class="pcoded-content">
          <div class="pcoded-inner-content">
            <div class="main-body">
              <div class="page-wrapper">
                <div class="row justify-content-center">
                  <div class="col-xs-12 col-md-9 col-xl-7">
                    <div class="row justify-content-end">
                      <div class="col-auto">
                        <language-element class="mr-1"></language-element>
                      </div>
                    </div>
                    <h2>404</h2>
                    <h1 class="mb-20">{{ $t('notFound') }}</h1>
                    <button class="btn btn-primary" @click="$router.push({ name: 'dashboard' })">{{ $t('backToHome') }}</button>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'
const LanguageElement = defineAsyncComponent(() => import('@/application/components/elements/LanguageElement.vue'))

</script>
